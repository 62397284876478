<template>
  <v-app>
    <section class="mt-4 pa-10">
      <v-row v-if="results">
        <v-col md="10" lg="8" class="py-0">
          <h1 class="red--text">
            Сэдэв: <span v-if="asuulga">{{ asuulga.name }}</span>
          </h1>
        </v-col>
        <v-col md="10" lg="10" class="pt-0">
          <v-row justify="space-between">
            <v-col>
              <h4>
                Сурагчийн тоо: {{ results2.length }}, Aваx оноо:
                {{ totalScore }}, Дундаж: {{ _getAverageTotalScore() }}, Max: {{
                  maxTotalScore
                }}
              </h4>
            </v-col>
          </v-row>
        </v-col>
        <v-col md="10" lg="12" class="mx-auto">
          <v-data-table
            :headers="headers"
            :items="results"
            hide-details
            hide-default-footer
            :items-per-page="-1"
            class="elevation-1"
          >
            <template slot="item" slot-scope="props">
              <tr>
                <td>{{ props.item.index }}</td>
                <td v-if="props.item.answerer.role == 'student'">
                  {{ props.item.answerer.firstName }}
                </td>
                <td v-else style="background: red; color: white">
                  <span
                    v-if="userData && userData.email == 'granditxxk@gmail.com'"
                    >, {{ props.item.ref.path }}</span
                  >
                </td>
                <td>{{ props.item.answerer.phone }}</td>
                <td>{{ props.item.totalScore }}</td>
                <td>{{ props.item.percent }}</td>
                <td
                  v-for="(cat, catindex) in props.item.categories"
                  :key="catindex"
                >
                  {{ cat.catScored }}
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
      <v-row v-else>
        <v-col md="8" class="mx-auto">
          <v-data-table
            loading
            loading-text="Loading... Please Wait"
            class="elevation-1"
          ></v-data-table>
        </v-col>
      </v-row>
    </section>
  </v-app>
</template>

<script>
import { mapState } from "vuex";
import { sync } from "vuex-pathify";
import VueSweetalert2 from "vue-sweetalert2";
import Vue from "vue";
Vue.use(VueSweetalert2);

const fb = require("../../../firebaseConfig.js");
export default {
  data: () => ({
    downloadLoading: false,
    resultCollectionName: "results-simplified", //simplified
    asuulga: null,
    schools: null,
    maxTotalScore: 0,
    sumOfAverageTotalScores: 0,
    closeSlided: false,
    showScores: false,
    firstName: null,
    tab: null,

    results: null,
    categories: null,
    loading: false,
    rules: [],

    headers: [
      {
        text: "No",
        align: "start",
        sortable: true,
        value: "index",
        width: "1%",
      },
      {
        text: "Нэр",
        align: "start",
        sortable: false,
        value: "answerer.firstName",
      },
      {
        text: "Утас",
        align: "start",
        sortable: true,
        value: "phone",
      },
      // {
      //   text: "Сургууль",
      //   align: "start",
      //   sortable: true,
      //   value: "schoolName",
      // },
      {
        text: "Нийт оноо",
        align: "start",
        sortable: true,
        value: "totalScore",
      },
      {
        text: "Xувь",
        align: "start",
        sortable: true,
        value: "percent",
      },
    ],
  }),
  props: {
    sorilId: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapState(["userProfile", "currentUser"]),
    ...sync("*"),
    totalScore() {
      //return this.categories.reduce((pre, el) => pre + el.totalScore?el.totalScore:0, 0);
      var score = 0;
      for (var category of this.categories) {
        if (category.totalScore) {
          score = score + category.totalScore;
        }
      }
      return score;
    },
    results2() {
      if (this.results)
        return this.results.filter((student) => student.skipped != true);
      else return [];
    },
  },
  created() {
    fb.db.doc(this.sorilId).onSnapshot((doc) => {
      let asuulga = doc.data();
      asuulga.id = doc.id;
      asuulga.ref = doc.ref;
      this.asuulga = asuulga;
    });
    fb.db
      .doc(this.sorilId)
      .collection("categories")
      .onSnapshot(async (docs) => {
        if (!docs.empty) {
          this.categories = [];
          docs.forEach((doc) => {
            let category = doc.data();
            category.ref = doc.ref;
            category.id = doc.id;
            this.categories.push(category);
          });

          for (var i = 0; i < this.categories.length; i++) {
            if (this.categories[i].totalScore) {
              var xx = i == 0 ? "Xэсэг-1" : "" + this.categories[i].id;
              this.headers.push({
                text: xx + " (" + this.categories[i].totalScore + ")",
                align: "start",
                value: "categories[" + i + "].totalScore",
              });
            } else {
              this.headers.push({
                text:
                  i == 0
                    ? "xэсэг-"
                    : "" + this.categories[i].id + " (өгөгдөөгүй)",
                align: "start",
                value: "categories[" + i + "].totalScore",
              });
            }
          }
        } else {
          // this.router.back()
          // alert("Шалгалтыг өгсөн сурагч байхгүй!")
          console.log("No such collection!");
        }
      });

    fb.db
      .doc(this.sorilId)
      .collection(this.resultCollectionName)
      .orderBy("totalScore", "desc")
      .onSnapshot(async (docs) => {
        if (!docs.empty) {
          this.results = [];
          var counter = 1;
          this.sumOfAverageTotalScores = 0;

          var batch = fb.db.batch();
          var batched = false;
          docs.forEach((doc) => {
            let result = doc.data();
            result.ref = doc.ref;
            result.id = doc.id;
            result.index = counter;
            result.percent =
              ((result.totalScore / this.totalScore) * 100).toFixed(0) + "%";

            if (result.deepAnalyzed == undefined) {
              batch.update(result.ref, { deepAnalyzed: false });
              batched = true;
            }

            // if (
            //   result.answerer.role == "student" &&
            //   result.schoolName != "Test2"
            // ) {
            this.results.push(result);

            if (result.skipped == true) {
              console.log("skipped", result.ref.path);
            } else {
              this.sumOfAverageTotalScores =
                this.sumOfAverageTotalScores + result.totalScore;
              if (this.maxTotalScore < result.totalScore)
                this.maxTotalScore = result.totalScore;
            }
            counter++;
            // }
          });
          if (batched) {
            batch.commit().then(() => {
              console.log("deep==false finished");
            });
          }
        } else {
          //this.$router.push({ name: "PageSoril Assignments" });
          // alert("Шалгалтыг өгсөн сурагч байхгүй!");
        }
      });

    fb.db
      .collection("schools")
      .where("realschool", "==", true)
      .get()
      .then((docs) => {
        this.schools = [];
        var counter = 1;
        docs.forEach((doc) => {
          let school = doc.data();
          school.id = doc.id;
          school.ref = doc.ref;
          school.index = counter;
          // var xx = this._getAvgScore2(school);
          // school.totalScore = xx[0];
          // school.numberOfStudents = xx[1];
          //this._saveReportData(school, xx);
          this.schools.push(school);
          counter++;
        });
      });
  },
  methods: {
    _getAvgScore2(school) {
      var score = 0;
      var counter = 0;
      if (this.results2) {
        for (var i = 0; i < this.results2.length; i++) {
          var student = this.results2[i];
          if (school.name == student.schoolName) {
            counter++;
            score = score + student.totalScore;
          }
        }
      }
      if (counter > 0) return [(score / counter).toFixed(2), counter];
      else return [score, counter];
    },

    _getAverageTotalScore() {
      if (this.results2)
        return (this.sumOfAverageTotalScores / this.results2.length).toFixed(2);
      else return 0;
    },
    _redirectResultsDetail(sorilId, tabIndex) {
      this.$router.push({
        name: "StudentShowAssignmentAllResultsDetail",
        params: {
          sorilId: sorilId,
          tabIndex: tabIndex,
        },
      });
    },
    _getSlideLink(cat) {
      var xx = null;
      if (cat.slideUrl)
        xx = cat.slideUrl.replace(
          "<iframe ",
          "<iframe style='width:100%!important;'"
        );
      return xx;
    },
  },
};
</script>
<style>
.theme--light.v-tabs-items {
  background-color: #eeeeee;
}
.asuulga-table td {
  text-align: center;
  border-left: 1px solid #e0e0e0;
  cursor: pointer;
}
.bordercell {
  text-align: center;
  border-left: 1px solid #e0e0e0;
  background: #f5f591 !important;
  cursor: pointer;
}
.asuulga-table {
  border: 1px solid #e0e0e0;
}

.xcontainer {
  position: relative;
  overflow: hidden;
  width: 100%;
  padding-top: 56.25%; /* 16:9 Aspect Ratio (divide 9 by 16 = 0.5625) */
}

/* Then style the iframe to fit in the container div with full height and width */
.xresponsive-iframe {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
}
</style>
 